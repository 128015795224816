<script>
import axios from 'axios';

import AuthorsInbox from "./_AuthorsInbox.vue";
import AuthorsSent from "./_AuthorsSent.vue";

export default {
  data() {
    return {
      data: {
      }
    };
  },
  props: {
  },
  components: {
    AuthorsInbox,
    AuthorsSent,
  },
  mounted() {
  },
  methods: {
    loadingData() {
      axios.get('/api/account/invites-authors', { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
  }
};
</script>

<template>
  <div class="card mt-xxl">
    <div class="card-header">
      <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-body active" data-bs-toggle="tab" href="#inbox" role="tab" aria-selected="true">
            Inbox
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-body" data-bs-toggle="tab" href="#sent" role="tab" aria-selected="false">
            Sent
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body p-4">
      <div class="tab-content">
        <div class="tab-pane active" id="inbox" role="tabpanel">
          <AuthorsInbox v-bind:data="data"></AuthorsInbox>
        </div>
        <div class="tab-pane" id="sent" role="tabpanel">
          <AuthorsSent v-bind:data="data"></AuthorsSent>
        </div>
      </div>
    </div>
  </div>
</template>