<script>
import axios from 'axios';

import GuestsInbox from "./_GuestsInbox.vue";
import GuestsSent from "./_GuestsSent.vue";

export default {
  data() {
    return {
      data: {
      }
    }
  },
  props: {
  },
  components: {
    GuestsInbox,
    GuestsSent
  },
  methods: {
    loadingData() {
      axios.get('/api/account/invites-guests', { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
  }
};
</script>

<template>
  <div class="card mt-xxl">
    <div class="card-header">
      <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-body active" data-bs-toggle="tab" href="#inbox" role="tab" aria-selected="true">
            Inbox
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-body" data-bs-toggle="tab" href="#sent" role="tab" aria-selected="false">
            Sent
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body p-4">
      <div class="tab-content">
        <div class="tab-pane active" id="inbox" role="tabpanel">
          <GuestsInbox v-bind:data="data"></GuestsInbox>
        </div>
        <div class="tab-pane" id="sent" role="tabpanel">
          <GuestsSent v-bind:data="data"></GuestsSent>
        </div>
      </div>
    </div>
  </div>
</template>