<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import AuthorInboxRow from "./_AuthorInboxRow.vue";

export default {
  data() {
    return {
      id: '',
      status: 0,
      podcast: '',
      title: '',
      memo: ''
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    AuthorInboxRow
  },
  methods: {
    openDialog: async function (id) {
      var vm = this;
      await axios.get('/api/account/invite-author/' + id, { withCredentials: true }).then((e) => {
        vm.data.id = e.data.invite.id;
        vm.data.status = e.data.invite.status;
        vm.data.podcast = e.data.podcast.title;
        vm.data.title = e.data.from.displayName;
        vm.data.memo = e.data.invite.memo;

        var form = document.getElementById('authorsInviteModal');
        var ins = new Modal(form, {});
        if(ins)
          ins.show();  
      }).catch(() => {
      });
    },
    closeDialog: function () {
    },
    acceptInvite: async function () {
      if(this.data.id !== '') {
        
        await axios.post('/api/account/invite-author/accept/' + this.data.id, null, { withCredentials: true }).then(() => {
          this.$router.go();
        }).catch(() => {
        });

      }
    },
    rejectInvite: async function () {
      if(this.data.id !== '') {
        
        await axios.post('/api/account/invite-author/reject/' + this.data.id, null, { withCredentials: true }).then(() => {
          this.$router.go();
        }).catch(() => {
        });

      }
    }
  },
  computed: {
    viewButtons: function() {
      return this.data.status == 1 || this.data.status == 2;
    }
  }
};
</script>

<template>

  <div class="table-responsive">
    <table class="table table-hover table-nowrap">
      <thead class="table-light text-muted">
        <tr>
          <th scope="col">Podcast</th>
          <th scope="col">From</th>
          <th scope="col">Date</th>
          <th scope="col" colspan="2">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in data.inbox" v-bind:key="p">
          <AuthorInboxRow v-bind:data="p" @open-dialog="openDialog"></AuthorInboxRow>
        </tr>
      </tbody>
    </table>
  </div>

  <div id="authorsInviteModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="myModalLabel">{{ data.podcast }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <h5 class="fs-15">Invite from {{ data.title }}:</h5>
                  <p class="text-muted pre-line">{{ data.memo }}</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-success" @click="acceptInvite" v-if="viewButtons">Accept</button>
                <button type="button" class="btn btn-danger" @click="rejectInvite" v-if="viewButtons">Reject</button>
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>

</template>