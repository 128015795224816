<script>
export default {
  emits: ["openDialog"],
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
    openDialog() {
      this.$emit('openDialog', this.data.invite.id);
    }
  },
  computed: {
    isOpenInvite: function() {
      return this.data.invite.status == 1 || this.data.invite.status == 2;
    },
    getTextStatusByEnum: function() {
      switch(this.data.invite.status) {
        case 1:
          return 'new';
        case 2:
          return 'opened';
        case 3:
          return 'accepted';
        case 4:
          return 'rejected';
        case 5:
          return 'expired';
        case 6:
          return 'canceled';
        default:
        case 0:
          return 'unknown';
      }
    }
  }
};
</script>

<template>
  <td>
    <div class="d-flex gap-2 align-items-center">
      <div class="flex-shrink-0">
        <router-link :to="{ name: 'episode', params: { id: data.episode.id } }">
          <div class="avatar-xs flex-shrink-0 me-1" v-if="!data.episode.urlAvatar">
            <div class="avatar-title rounded-circle">{{ data.episode.title[0].toUpperCase() }}</div>
          </div>
          <img :src="data.episode.urlAvatar" alt="" class="avatar-xs rounded-circle" v-if="data.episode.urlAvatar">
        </router-link>
      </div>
      <div class="flex-grow-1">
        <router-link :to="{ name: 'episode', params: { id: data.episode.id } }">
          <span>{{ data.episode.title }}</span>
          <br />
          <span>{{ data.episode.short }}</span>
        </router-link>
      </div>
    </div>
  </td>
  <td>
    <div class="d-flex gap-2 align-items-center">
      <div class="flex-shrink-0">
        <router-link :to="{ name: 'profile', params: { login: data.from.login } }">
          <div class="avatar-xs flex-shrink-0 me-1" v-if="!data.from.urlAvatar">
            <div class="avatar-title rounded-circle">{{ data.from.login[0].toUpperCase() }}</div>
          </div>
          <img :src="data.from.urlAvatar" alt="" class="avatar-xs rounded-circle" v-if="data.from.urlAvatar">
        </router-link>
      </div>
      <div class="flex-grow-1">
        <router-link :to="{ name: 'profile', params: { login: data.from.login } }">
          <span>{{ data.from.login }}</span>
          <br />
          <span>{{ data.from.displayName }}</span>
        </router-link>
      </div>
    </div>
  </td>
  <td class="align-middle">
    <span class="badge badge-soft-light">created at</span>
    {{ new Date(data.invite.createdAt).toLocaleString() }}
    <br/>
    <div v-if="data.invite.closedAt">
      <span class="badge badge-soft-light">closed at</span>
      {{ new Date(data.invite.closedAt).toLocaleString() }}
    </div>
  </td>
  <td class="align-middle"><span class="badge badge-soft-light">{{ getTextStatusByEnum }}</span></td>
  <td class="text-end">
    <a @click="openDialog" class="btn" :class="{ 'btn-primary': isOpenInvite, 'btn-light': !isOpenInvite }">Open</a>
  </td>
</template>