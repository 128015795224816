<script>
import ProfileItem from "../../profiles/ProfileItem.vue";

export default {
  data() {
    return {
      menu: 'authors'
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    ProfileItem,
  },
  methods: {
    selectAccountMenu(item) {
      this.menu = item;
      this.$emit('changedSidebarMenu', item);
    },
  }
};
</script>

<template>
  <div class="team-list grid-view-filter row">

    <div class="col-12">
      <ProfileItem v-bind:data="data.profile" v-if="data.profile"></ProfileItem>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-0">Invites</h5>
        </div>
        <b-list-group class="list-group-fill-primary list-group-flush">
          <b-list-group-item :active="menu === 'authors'" v-on:click="selectAccountMenu('authors')" class="list-group-item-action" role="button">
            <i class="ri-user-heart-line align-middle me-2"></i> Authors
          </b-list-group-item>
          <b-list-group-item :active="menu === 'guests'" v-on:click="selectAccountMenu('guests')" class="list-group-item-action" role="button">
            <i class="ri-user-received-line align-middle me-2"></i> Guests
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

  </div>
</template>