<script>
export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
  }
};
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-nowrap">
      <thead class="table-light text-muted">
        <tr>
          <th scope="col">Podcast</th>
          <th scope="col">From</th>
          <th scope="col">Date</th>
          <th scope="col" colspan="2">Status</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>