<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import Container from "@/components/Container.vue";
import appConfig from "../../../../app.config";

import InvitesSidebar from "./InvitesSidebar.vue";
import InvitesMenuAuthors from "./InvitesMenuAuthors.vue";
import InvitesMenuGuests from "./InvitesMenuGuests.vue";

export default {
  page: {
    title: "Invites",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Invites",
      menu: 'authors',
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    InvitesSidebar,
    InvitesMenuAuthors,
    InvitesMenuGuests,
  },
  created: function () {
  },
  mounted() {
    this.$refs.authorsPanel.loadingData();
  },
  methods: {
    sidebarMenuChanged: function(e) {
      this.menu = e;
      this.$nextTick(() => {
        if(this.menu === 'authors')
          this.$refs.authorsPanel.loadingData();
        if(this.menu === 'guests')
          this.$refs.guestsPanel.loadingData();
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />
    
    <Container>
      <template #sidebar>
        <InvitesSidebar @changed-sidebar-menu="sidebarMenuChanged"></InvitesSidebar>
      </template>
      <template #content>
        <InvitesMenuAuthors ref="authorsPanel" v-if="menu==='authors'"></InvitesMenuAuthors>
        <InvitesMenuGuests ref="guestsPanel" v-if="menu==='guests'"></InvitesMenuGuests>
      </template>
    </Container>
  </Layout>
</template>
